import React from "react";
import {
  SEO,
  PageLayout,
  Content,
  OutboundLink,
  InternalLink,
  MarkdownContent,
  MultipleComponentIterator,
  FullImageCard,
  FishermanIcon,
  BasicCarousel,
  Image,
  multipleComponentIterator,
  ReviewV2,
  SocialMediaV2,
} from "@bluefin/components";
import { Embed, Header, Button, Grid, p, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import SecondaryLayout from "../components/SecondaryLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withNamedArgs,
  getBusinessFiles,
} from "../utils/utils";
import { createServiceCategorySlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";
import "../style/index.css";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      bookingUrl,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessFile,
    } = data;

    return (
      <SecondaryLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"home-page"}>
          <div className={"home-hero-video-container"}>
            <div className={"hero-overlay"} />
            <Embed
              url={"https://player.vimeo.com/video/899306678?background=1"}
              autoplay={true}
              defaultActive={true}
              defaultActive={true}
              className={"home-hero-video"}
            />
            <div className={"hero-content"}>
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "home_section_header",
                  defaultValue: "Home",
                })}
              />
              <Content textAlign={"center"}>
                <Content.Markup
                  width={8}
                  isMarkdown={true}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "home_section_description",
                  })}
                  extra={
                    <div className={"ctas-container"}>
                      <Button
                        primary={true}
                        as={OutboundLink}
                        to={bookingUrl.url}
                        event={{
                          category: "Booking",
                          action: "Schedule Appointment Intent",
                        }}
                      >
                        {bookingUrl.title}
                      </Button>
                      <Button
                        primary={true}
                        to={"/services/"}
                        as={InternalLink}
                      >
                        Our Services
                      </Button>
                    </div>
                  }
                />
              </Content>
            </div>
          </div>
          <div className={"about-section"}>
            <Grid stackable={true} doubling={true} columns={2}>
              <Grid.Column width={8} className={"markdown-column"}>
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "about_section_description",
                  })}
                />
              </Grid.Column>
              <Grid.Column width={8} className={"image-column"}>
                <Embed
                  url={"https://player.vimeo.com/video/899313917?background=1"}
                  autoplay={true}
                  defaultActive={true}
                />
              </Grid.Column>
            </Grid>
          </div>
          <div className={"services-section"}>
            <Header
              as={"h2"}
              content={"Elevate Your Beauty with Our Expert Services"}
              textAlign={"center"}
            />
            <p>
              Explore our range of advanced aesthetic treatments, designed to
              enhance your natural beauty and deliver radiant, long-lasting
              results. From skin rejuvenation to hair removal, each service is
              personalized to meet your unique needs.
            </p>
            <Card.Group className={"services"} itemsPerRow={3} stackable={true}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessMenuCategory.nodes}
                components={[
                  {
                    component: (
                      <FullImageCard
                        link={true}
                        as={InternalLink}
                        event={{
                          category: "Services",
                          action: "View Category Details",
                        }}
                      />
                    ),
                    propMap: {
                      key: "name",
                      image: withNamedArgs({
                        func: getBusinessFiles,
                        args: {
                          businessFiles: allFishermanBusinessFile.nodes,
                          numToSelect: 1,
                        },
                      }),
                      "event.label": "name",
                      to: createServiceCategorySlug,
                    },
                    children: [
                      {
                        component: <Card.Header />,
                        propMap: { content: "name", key: "name" },
                      },
                      { component: <FishermanIcon iconName={"arrow-right"} /> },
                    ],
                  },
                ]}
                limit={6}
              />
            </Card.Group>
            <Button
              primary={true}
              to={"/services/"}
              event={{ category: "Services", action: "Discover Our Services" }}
              as={InternalLink}
            >
              See all Services
            </Button>
          </div>
          <div className={"stylists-section"}>
            <Header
              as={"h2"}
              content={"Real Results, Real Confidence"}
              textAlign={"center"}
            />
            <p>
              Discover the amazing transformations our clients experience
              through our advanced treatments.
            </p>
            <BasicCarousel
              className={"featured-stylists"}
              size={3}
              displayIndicators={false}
              displayPlayPauseButton={true}
              autoScroll={true}
              slide={true}
              items={[
                <Image
                  centered={true}
                  key={"1"}
                  src={
                    "https://fisherman.gumlet.io/public/131d5cab-28b9-4388-a125-5ffaaf810507/image3_2.jpeg"
                  }
                />,
                <Image
                  centered={true}
                  key={"2"}
                  src={
                    "https://fisherman.gumlet.io/public/131d5cab-28b9-4388-a125-5ffaaf810507/image2_2.jpeg"
                  }
                />,
                <Image
                  centered={true}
                  key={"3"}
                  src={
                    "https://fisherman.gumlet.io/public/131d5cab-28b9-4388-a125-5ffaaf810507/image0_6.jpeg"
                  }
                />,
                <Image
                  centered={true}
                  key={"4"}
                  src={
                    "https://fisherman.gumlet.io/public/131d5cab-28b9-4388-a125-5ffaaf810507/image.jpg"
                  }
                />,
                <Image
                  centered={true}
                  key={"5"}
                  src={
                    "https://fisherman.gumlet.io/public/131d5cab-28b9-4388-a125-5ffaaf810507/image2.jpg"
                  }
                />,
                <Image
                  centered={true}
                  key={"6"}
                  src={
                    "https://fisherman.gumlet.io/public/131d5cab-28b9-4388-a125-5ffaaf810507/ig1.jpg"
                  }
                />,
                <Image
                  centered={true}
                  key={"7"}
                  src={
                    "https://fisherman.gumlet.io/public/131d5cab-28b9-4388-a125-5ffaaf810507/ig3.jpg"
                  }
                />,
              ]}
            />
            <Button
              primary={true}
              to={"/before-after-photos/"}
              as={InternalLink}
            >
              View More Transformations
            </Button>
          </div>
          <Grid
            stackable={true}
            verticalAlign={"top"}
            className={"specials-section"}
            textAlign={"center"}
            centered={true}
            columns={3}
          >
            <Grid.Column width={16} textAlign={"center"} centered={true}>
              <Header
                as={"h2"}
                content={"Discover our Holiday Glow Bundles"}
                textAlign={"center"}
              />
              <p>
                Discover our best deals designed to enhance your beauty routine.
                Limited-time offers—book now to lock in these amazing savings!
              </p>
            </Grid.Column>
            <Grid.Row stretched={true}>
              <Grid.Column
                tablet={6}
                computer={5}
                largeScreen={3}
                textAlign={"center"}
              >
                <h3>Morpheus8 Full Face & Neck</h3>
                <div>
                  <span>3 Sessions for&nbsp;</span>
                  <span className={"strikethrough"}>$4200</span>
                  <span>&nbsp;$1900</span>
                </div>
                <div>
                  Lift and tighten your face and neck with our most advanced
                  skin treatment.
                </div>
                <Button
                  to={"https://trumedspa.myaestheticrecord.com/online-booking"}
                  as={OutboundLink}
                  event={{
                    category: "Booking",
                    action: "Schedule Appointment Intent",
                  }}
                  content={"Book Now"}
                  aria-label={"Book an Appointment"}
                  primary={true}
                />
              </Grid.Column>
              <Grid.Column
                tablet={6}
                computer={5}
                largeScreen={3}
                textAlign={"center"}
              >
                <h3>Morpheus8 Full Face</h3>
                <div>
                  <span>3 Sessions for&nbsp;</span>
                  <span className={"strikethrough"}>$2400</span>
                  <span>&nbsp;$1500</span>
                </div>
                <div>
                  Smooth out your wrinkles and tighten your skin to achieve
                  lasting rejuvenation.
                </div>
                <Button
                  to={"https://trumedspa.myaestheticrecord.com/online-booking"}
                  as={OutboundLink}
                  event={{
                    category: "Booking",
                    action: "Schedule Appointment Intent",
                  }}
                  content={"Book Now"}
                  aria-label={"Book an Appointment"}
                  primary={true}
                />
              </Grid.Column>
              <Grid.Column
                tablet={6}
                computer={5}
                largeScreen={3}
                textAlign={"center"}
              >
                <h3>Forma Full Face & Neck</h3>
                <div>
                  <span>3 Sessions for&nbsp;</span>
                  <span className={"strikethrough"}>$1050</span>
                  <span>&nbsp;$999</span>
                </div>
                <div>
                  Lift, tighten, and contour by stimulating collagen for firmer,
                  smoother skin.
                </div>
                <Button
                  to={"https://trumedspa.myaestheticrecord.com/online-booking"}
                  as={OutboundLink}
                  event={{
                    category: "Booking",
                    action: "Schedule Appointment Intent",
                  }}
                  content={"Book Now"}
                  aria-label={"Book an Appointment"}
                  primary={true}
                />
              </Grid.Column>
              <Grid.Column
                tablet={6}
                computer={5}
                largeScreen={3}
                textAlign={"center"}
              >
                <h3>Forma Full Face</h3>
                <div>
                  <span>3 Sessions for&nbsp;</span>
                  <span className={"strikethrough"}>$600</span>
                  <span>&nbsp;$550</span>
                </div>
                <div>
                  Improve skin texture, elasticity, and contours with
                  radiofrequency treatment.
                </div>
                <Button
                  to={"https://trumedspa.myaestheticrecord.com/online-booking"}
                  as={OutboundLink}
                  event={{
                    category: "Booking",
                    action: "Schedule Appointment Intent",
                  }}
                  content={"Book Now"}
                  aria-label={"Book an Appointment"}
                  primary={true}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Button
                to={"/specials/"}
                as={InternalLink}
                content={"Explore Our Specials"}
                primary={true}
              />
            </Grid.Row>
          </Grid>
          <div className={"reviews-section"}>
            <Header
              as={"h2"}
              textAlign={"center"}
              content={"What Our Clients Are Saying"}
            />
            <BasicCarousel
              size={3}
              displayIndicators={false}
              stepSize={1}
              autoScroll={false}
              displayPlayPauseButton={false}
              animation={{ name: "blur", duration: 160 }}
              items={multipleComponentIterator({
                iterator: fishermanBusiness.reviews,
                components: [
                  {
                    component: <ReviewV2 />,
                    propMap: { key: "_id" },
                    children: [
                      { component: <FishermanIcon iconName={"quote-left"} /> },
                      {
                        component: <ReviewV2.Text charLimit={360} />,
                        propMap: { text: "text" },
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          { component: <ReviewV2.Rating rating={5} /> },
                          {
                            component: <ReviewV2.RatingLabel />,
                            propMap: { rating: "rating" },
                          },
                        ],
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Author />,
                            propMap: { author: "author" },
                          },
                          {
                            component: <ReviewV2.Source />,
                            propMap: { source: "source", link: "link" },
                          },
                        ],
                      },
                    ],
                  },
                ],
              })}
            />
          </div>
          <div className={"image-gallery-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_section_header",
                defaultValue: "WE'RE SOCIAL",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_section_description",
              })}
            />
            <SocialMediaV2>
              <MultipleComponentIterator
                iterator={fishermanBusiness.socialMedia}
                components={[
                  {
                    component: <SocialMediaV2.Platform />,
                    propMap: { key: "_id", url: "link", platform: "type" },
                    children: [
                      {
                        component: <SocialMediaV2.Icon />,
                        propMap: { icon: "type" },
                      },
                      {
                        component: <SocialMediaV2.Handle />,
                        propMap: { link: "link" },
                      },
                    ],
                  },
                ]}
              />
            </SocialMediaV2>
            <div
              className={"elfsight-app-49969dd7-134d-43d0-8882-9aee356f1f93"}
              data-elfsight-app-lazy={true}
            />
          </div>
        </PageLayout>
      </SecondaryLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      reviews {
        _id
        author
        text
        source
        rating
        link
      }
      socialMedia {
        _id
        link
        type
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
        contentNodes {
          content
        }
      }
    }
    bookingUrl: fishermanBusinessWebsitePage(
      title: { regex: "/Book Now|Make An Appointment/" }
      pageType: { eq: "External" }
    ) {
      url
      title
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        description
        name
        _id
        files
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
